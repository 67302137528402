<template>
  <div class="pt-15 my-10 main-component-content">
    <steps-component/>
    <v-container>
      <div >
        <iframe width="100%" height="831" src="https://www.youtube.com/embed/rpDzWdKrq2w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <p class="text-left w-full mt-2">
        El video tutorial es referencial sobre el uso de la plataforma,
        sin embargo, las bases administrativas del año en curso se
        encuentran disponibles a continuación:
      </p>
      <div v-if="pdf" class="fixedHeightScroll secondary-color">
      <v-btn
          style="background-color: #EA7730; color: white;"
          dark
          class="mr-md-1 welcome-link-group"
          @click="download()"
          elevation="0"> Descargar PDF
      </v-btn>
      <transition name="slide-fade">
        <div v-if="!pdfLoader" class="pa-5">
          <span class="white--text">Cargando pdf
            <strong>{{ Math.floor((pdfNumber / pdf.totalPages) * 100) }}%</strong></span>
          <spinner-component class="mt-4"/>
        </div>
      </transition>
      <pdf-component
          v-for="(page, pageIndex) in pdf.totalPages"
          :key="pageIndex"
          :src="pdf.file"
          :page="page"
          @progress="load($event,page,pdf.totalPages)">
      </pdf-component>
    </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import pdf from 'vue-pdf'
import Spinner from "@/components/animations/Spinner.vue";
import StepComponent from "@/components/steps/Stepper.vue";
import Swal from "sweetalert2";

export default {
  components: {
    'pdf-component': pdf,
    'spinner-component': Spinner,
    'steps-component': StepComponent
  },
  data() {
    return {
      pdf: {
        file: '',
        totalPages: 0,
        name: ''
      },
      pdfLoader: false,
      pdfNumber: 0,
    };
  },
  async created() {
    await this.getFilePeriodActive();
  },
  methods: {
    ...mapActions('main', [
        'showFile'
    ]),
    download(){
      var a = document.createElement("a");
      a.href = this.pdf.file;
      a.download = this.pdf.name;
      a.click();
      a.remove();
    },
    load(event,current,total) {
      this.pdfNumber = current;
      if (event === 1 && current > ((total/5)*4)) {
        this.pdfLoader = true;
      }
    },
    getFilePeriodActive(){
      this.showFile().then(response => {
        this.pdf.file = "data:" + response.data.response.mime + ";base64," + response.data.response.file;
        this.pdf.totalPages = response.data.response.pages;
        this.pdf.name = response.data.response.filename;
      }).catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Bases Administrativas',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.pdfLoader = true;
        this.loading = false;
      });
    },
    ...mapActions('authentication', ['setToken',]),
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    }),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
};
</script>

<style scoped>
.main-component-content {
  min-height: 100vh;
  width: 100%;
}
.welcome-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.welcome-link {
  cursor: pointer;
  text-decoration: underline;
}
.welcome-link:not(:last-child) {
  margin-right: 1em;
}
.border-t {
  border-top: 1px solid rgba(0,0,0,.12);
}
.border-r {
  border-right: 1px solid rgba(0,0,0,.12);
}
.text-transform-none {
  text-transform: none;
}
.home-background-card {
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
